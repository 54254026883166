import React from 'react';
import './Banner.css';
import banner from '../../Images/bannerr.png'; // Ensure the correct path to your image

function Banner() {
  return (
    <section className="banner">
      <div className="banner-image-container"> 
        <img className='banner-img' src={banner} alt='Lab Center Banner' />
      </div>
    </section>
  );
}

export default Banner;
