import React from "react";
import "./Aboutus.css";
import aboutImg from "../../Images/about10.png";
import hospitalLogo from "../../Images/pregnant.png"; // Import your hospital logo image

function AboutUs() {
  return (
    <div className="about-section">
      <div className="imageleft">
        <img src={aboutImg} alt="About Logo" className="about-img" />
      </div>

      <div className="about-content">
        <div className="logo-contain">
          <h1 className="heading">Have you heard about us?</h1>
          <img
            src={hospitalLogo}
            alt="About Logo"
            className="about-logo"
          />
        </div>
        < div className="caption">
          <p>
            Sudha Hospital is a 40-year-old institution dedicated to the
            betterment of human life through competent & compassionate medical
            care at affordable prices for the common man.
          </p>
          <p>
            Sudha Hospital's journey started on 6th July 1972 with Dr. Prasad
            Rao, who is a General Surgeon in the district of West Godavari with
            a 20-bedded Hospital. In 1985, the clinic expanded to a 30-bedded
            hospital with one OT. Later in 2005, Dr. Ravi Shankar, also a
            Surgical Gastroenterologist, joined the hospital. In 2008, Dr. Prashanthi joined, and over the years,
            increased the capacity to 100 beds. The number of specialties also
            increased, which includes GI Surgeries, Orthopedics, Urology,
            Pediatrics, Gynecology & Obstetrics, and Infertility center.
          </p>
          <p>
            Recently in 2015, with the addition of a new building, the hospital
            bed strength has increased to 130 beds, and new facilities like
            Cardiac Cath Lab and CT have also been added.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
