import React, { useState, useEffect } from "react";
import "./Header.css"; // Import your CSS file for styling
import logo from "../../Images/sudha2.png";

function Header() {
  const [showOptions, setShowOptions] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleButtonClick = () => {
    // After a short delay, redirect to the call app or appropriate action
    // This will work on most mobile devices and some browsers
    window.location.href = "tel:+918333833030";

    // Additional logic for handling desktop browsers can be added here
    // For example, you could open a new window/tab with the phone number
    // or display instructions on how to make a call
  };

  const handleOptionClick = (option) => {
    switch (option) {
      case "call":
        window.location.href = "tel:+918333833030";
        break;
      case "whatsapp":
        window.location.href =
          "https://api.whatsapp.com/send?phone=+918333833030";
        break;
      // Add cases for 'chat' and 'messaging app' as you integrate those features
      default:
        break;
    }
    setShowOptions(false); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`header-wrapper ${scrolled ? "scrolled" : ""}`}>
      <div className="header">
        <div className="head-container">
          <img className="logo" src={logo} alt="Hospital Logo" />

          <nav>
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About Us</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
              {/* Remove contact page link */}
              <li>
                <button onClick={handleButtonClick} className="cta-button">
                  Book an Appointment
                </button>

                {showOptions && (
                  <div className="booking-options">
                    <button onClick={() => handleOptionClick("call")}>
                      Call
                    </button>
                    <button onClick={() => handleOptionClick("whatsapp")}>
                      WhatsApp
                    </button>
                    {/* Add buttons for 'chat' and 'messaging app' later */}
                  </div>
                )}
              </li>
            </ul>
          </nav>

          <button onClick={handleButtonClick} className="tta-button">
            Book Appointment
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
