import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="foot">
        <p className="fot">&copy; 2024, sudhaivf.com  All Rights Reserved</p>
        <p className="fot">Built and Developed by <a href="https://www.4sdigilabs.com" target="_blank" rel="noopener noreferrer">www.4sdigilabs.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
