import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import "./Ourdoctors.css";
import doctorLogo from "../../Images/doctor.png";

const FlipCard = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [flipEnabled, setFlipEnabled] = useState(window.innerWidth > 768);


  const handleClick = () => {
    if (flipEnabled) {
      setIsFlipped(!isFlipped);
    }
  };

  const handleResize = () => {
    setFlipEnabled(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  

  const person = {
    name: "Dr. K. Prasanthi",
    title: "Gynaecologist & Infertility Specialist",
    info: "Meet Dr. K. Prasanthi, a dedicated and accomplished medical professional specializing in Obstetrics, Gynecology, and Infertility. With a comprehensive background including an M.B.B.S. and M.S. in Obstetrics and Gynecology, Dr. Prasanthi brings a wealth of knowledge and expertise to her practice.",
    info2:
      "Her commitment to excellence led her to pursue further specialization, including a Fellowship in Reproductive Medicine from CIMAR and completion of the CREST program in Singapore. Dr. Prasanthi's international training also includes a Diploma in Reproductive Medicine & Embryology from ISOM in Germany.",
    info3:
      "With her extensive training and experience, Dr. Prasanthi is not only skilled in diagnosing and treating a wide range of women's health issues but is also deeply committed to helping couples struggling with infertility achieve their dream of parenthood.",
  };

  return (
    <div className="menu-doctors">
      <div className="logo-cont">
        <h1 className="heading">Meet Our Doctor</h1>
        <img src={doctorLogo} alt="Doctor Logo" className="doctor-logo" />
      </div>
      {flipEnabled ? (
      <ReactCardFlip 
        isFlipped={isFlipped}
        flipDirection="vertical"
        flipSpeedBackToFront={2}
        flipSpeedFrontToBack={2}
      >
        {/* Front of the card */}
        <div className="front">
          <h2>{person.name}</h2>
          <p>{person.title}</p>
          <button className="info-button" onClick={handleClick}>
            Info
          </button>
        </div>

        {/* Back of the card */}
        <div className="back">
          <h2>{person.name}</h2>
          <p className="doc-info">{person.info}</p>
          <p className="doc-info">{person.info2}</p>
          <p className="doc-info">{person.info3}</p>
          <button className="info-button" onClick={handleClick}>
            Back
          </button>
        </div>
      </ReactCardFlip>
    ) : (
        <div className="front">
          <div className="docc">
          <p className="doc-tit">{person.name}</p>
          <p className="doc-tit">{person.title}</p>
          </div>
          <p className="doc-info">{person.info}</p>
          <p className="doc-info">{person.info2}</p>
          <p className="doc-info">{person.info3}</p>
        </div>
      )}
    </div>
  );
};

export default FlipCard;
