import React from "react";
import "./Reasons.css"; // Create a CSS file for styling
import reasonsLogo from "../../Images/reasons.png"

function Reasons() {
  const reasonsData = [
    // Your data for each reason goes here (title, description, icon)
    {
      title: "The collective experience of more than 40+ years",
      description:
        "Clinical Expertise with over 40+ years of experience available for you 24/7.",
      icon: "🏆",
    },
    {
      title: "All Under One Roof",
      description:
        "All infertility treatments ranging from conventional IUI to advanced PGD/PGS under one roof- A choice of treatments to choose what is right for you.",
      icon: "☂️",
    },
    {
      title: "Ethical Practices",
      description:
        "We strongly believe in following ethical practices and abide by all the national and international guidelines set for ART centres.",
      icon: "⚖️",
    },
    {
      title: "Transparent Pricing",
      description:
        "We believe in having an open and transparent conversation with our patients which includes the cost of the procedures involved in the treatment.",
      icon: "🤝",
    },
    {
      title: "Individualised Protocols",
      description:
        "Sudha IVF believes that every individual is unique and should be treated as such, right from diagnosis to the treatment plan.",
      icon: "👤",
    },
    {
      title: "Patient Centric Approach",
      description:
        "Sudha IVF Fertility believes in offering compassionate, supportive and patient-centric care.",
      icon: "💜",
    },
    // ... (Add the rest of your reasons)
  ];

  return (
    <section className="sudha-reasons">
      <div className="loggo-container">
        <h1 className="heading">6 Reasons To Choose Sudha IVF</h1>
        <img src={reasonsLogo} alt="Reasons Logo" className="reasons-logo" />
      </div>
      <p className="captionn">
        Sudha IVF is a trusted and leading healthcare service provider in the
        field of reproductive medicine and is dedicated helping couples to
        experience the wonderful bond called family
      </p>

      <div className="reason-grid">
        {reasonsData.map((reason) => (
          <div className="reason-item" key={reason.title}>
            <div className="reason-icon">{reason.icon}</div>
            <h3>{reason.title}</h3>
            <p className="captionn">{reason.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Reasons;
