import React, { useRef, useEffect } from 'react';
import Header from '../Components/Header/Header';
import Banner from '../Components/Banner/Banner';
import Ourdoctors from '../Components/Elements/Ourdoctors';
import Footer from '../Components/Footer/Footer';
import Aboutus from '../Components/AboutUs/Aboutus';
import OurHospital from '../Components/OurHospital/OurHospital';
import Reasons from '../Components/Reasons/Reasons';
import Treatments from '../Components/Treatments/Treatments';

function Homepage() {
  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);
  const servicesRef = useRef(null);

  useEffect(() => {
    const hash = window.location.hash; // Get hash from URL
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove '#'
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id="home">
      <Header />
      <Banner />
      <Ourdoctors />
      <div id="services" ref={servicesRef}> {/* Attach ref to the AboutUs section */}
        <Treatments />
      </div>
      <Reasons/>
      <div id="about" ref={aboutUsRef}> {/* Attach ref to the AboutUs section */}
        <Aboutus />
      </div>
      <div id="contact" ref={contactUsRef}> {/* Attach ref to the ContactUs section */}
        <OurHospital />
      </div>
      <Footer />
    </div>
  );
}

export default Homepage;
