import React from 'react';
import './Treatments.css'; // Import your CSS file for styling

import IvmIcon from "../../Images/t1.png"; 
import IuiIcon from "../../Images/IUI.png";
import IvfIcon from "../../Images/IVF.png";
import PreservationIcon from "../../Images/t10.png";
import PgsIcon from "../../Images/t7.png";
import PgdIcon from "../../Images/PGD.png";
import treatmentsLogo from "../../Images/treatments.png"

const treatmentsData = [
  {
    title: 'IVM - In Vitro Maturation of Oocytes',
  },
  {
    title: 'IUI - Intra Uterine Insemination',
  },
  {
    title: 'IVF - In Vitro Fertilization',
  },
  {
    title: 'Fertility Preservation',
  },
  {
    title: 'PGS - Pre Implantation Genetic Screening',
  },
  {
    title: 'PGD: Pre Implantation Genetic Diagnosis',
  },
];

function Treatments() {

    const iconMap = {
        "IVM - In Vitro Maturation of Oocytes": IvmIcon,
        "IUI - Intra Uterine Insemination": IuiIcon,
        "IVF - In Vitro Fertilization": IvfIcon,
        "Fertility Preservation": PreservationIcon,
        "PGS - Pre Implantation Genetic Screening": PgsIcon,
        "PGD: Pre Implantation Genetic Diagnosis": PgdIcon,
      };

  return (
    <section className="treatments-section">
      <div className="logo-container">
          <h1 className="heading">Our Treatments</h1>
          <img
            src={treatmentsLogo}
            alt="treatments Logo"
            className="treatments-logo"
          />
        </div>
      <p className='captio'>
        Our expert team is adept in addressing any of your fertility needs and we at Oasis pride ourselves in offering efficient and evidence-based fertility treatments such as IUI, IVF, IVM, PGS etc.
      </p>

      <div className="treatments-grid">
        {treatmentsData.map((treatment) => (
          <div className="treatment-item" key={treatment.title}>
            <img src={iconMap[treatment.title]} alt={treatment.title} />
            <p className='captio'>{treatment.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Treatments;
