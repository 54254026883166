import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./OurHospital.css";
import hospitalLogo from "../../Images/hospital.png"

const OurHospital = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      fetch("http://localhost:5000/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          toast.success("Thank you! Your message has been sent.");
          setIsSubmitting(false);
          setFormData({ name: "", email: "", message: "" });
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("An error occurred while submitting the form.");
          setIsSubmitting(false);
        });
    }
  };

  const openGoogleMaps = () => {
    window.open(
      "https://www.google.com/maps/place/Sudha+Hospital/@16.757475,81.6813272,15z/data=!4m6!3m5!1s0x3a37b7f03e1f0859:0x7b3931b221596686!8m2!3d16.757475!4d81.6813272!16s%2Fg%2F1tlnbvq5?entry=ttu",
      // "_blank"
    );
  };

  return (
    <div className="our-hospital">
      <ToastContainer />
      <div className="logo-container">
          <h1 className="heading">Our Hospital</h1>
          <img
            src={hospitalLogo}
            alt="Hospital Logo"
            className="hospital-logo"
          />
        </div>
      <div className="both">
        <section className="hospital-location">
          <div className="located">
            <h1 className="heading">Our Branch</h1>
            <p>Location: No.21, 1-4, Society Rd, opp. Co-operative Stores, Venkatarayapuram, Tanuku, Andhra Pradesh, 534211</p>
            <button className="map-button" onClick={openGoogleMaps}>
              View on Google Maps
            </button>
          </div>
        </section>
        <section className="contact-us">
          <div className="locate">
            <h1 className="heading">Contact Us</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {formErrors.name && <p className="error">{formErrors.name}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formErrors.email && <p className="error">{formErrors.email}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                {formErrors.message && (
                  <p className="error">{formErrors.message}</p>
                )}
              </div>
              <button
                className="form-button"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurHospital;
